(function () {
  'use strict';

  angular
    .module('home')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home', {
        url: '/home?view',
		params:{
			view:{squash:true}
		},
		views:{
			navigation: {
			   templateUrl: 'navigation/navigation.tpl.html',
			   controller: 'NavigationCtrl',
			   controllerAs: 'navigation'
			},
			alerts: {
			   templateUrl: 'alerts/alerts.tpl.html',
			   controller: 'AlertsCtrl',
			   controllerAs: 'alerts'
			},
			main:{
				templateUrl: 'home/home.tpl.html',
				controller: 'HomeCtrl',
				controllerAs: 'home'
			},
			aside:{
				templateUrl: 'messages/messages.tpl.html',
				controller: 'MessagesCtrl',
				controllerAs: 'messages'
			},
			footer: {
			   templateUrl: 'footer/footer.tpl.html',
			   controller: 'FooterCtrl',
			   controllerAs: 'footer'
			 }
		}
      });
	  
  }
}());
